<script setup lang="ts">

import { onMounted, onUnmounted, ref, computed } from 'vue'
import { usePage, Head } from '@inertiajs/vue3'
import TheSchema from '@/Components/TheSchema.vue'

const page = usePage()

const ogImage = computed(() => page.props.metaData.ogThumbnail ?? '/images/hostelz-medium.png')

const defaultTitle = page.props.metaData.title ?? ''
const title = ref(defaultTitle)

const activeFavicons = {
    favicon: '/favicon.ico',
    favicon32: '/favicon-32x32.png',
    favicon16: '/favicon-16x16.png',
    appleTouch120: '/apple-icon-120x120.png',
    appleTouch152: '/apple-icon-152x152.png',
    appleTouch180: '/apple-touch-icon.png',
    maskIcon: '/safari-pinned-tab.svg',
    maskIconColor: '#FFFFFFF'
}

const inactiveFavicons = {
    favicon: '/favicon-yellow.ico',
    favicon32: '/favicon-yellow.png',
    favicon16: '/favicon-yellow.png',
    appleTouch120: '/favicon-yellow.png',
    appleTouch152: '/favicon-yellow.png',
    appleTouch180: '/favicon-yellow.png',
    maskIcon: '/safari-pinned-tab.svg',
    maskIconColor: '#FFFFFF'
}

const setFavicons = (isActive: boolean): void => {
    const iconsToUse = isActive ? activeFavicons : inactiveFavicons

    document.getElementById('favicon')?.setAttribute('href', iconsToUse.favicon)
    document.getElementById('favicon32')?.setAttribute('href', iconsToUse.favicon32)
    document.getElementById('favicon16')?.setAttribute('href', iconsToUse.favicon16)
    document.getElementById('appleTouch120')?.setAttribute('href', iconsToUse.appleTouch120)
    document.getElementById('appleTouch152')?.setAttribute('href', iconsToUse.appleTouch152)
    document.getElementById('appleTouch180')?.setAttribute('href', iconsToUse.appleTouch180)
   
    const maskIconElement = document.getElementById('maskIcon')

    if (maskIconElement) {
        maskIconElement.setAttribute('href', iconsToUse.maskIcon)
        maskIconElement.setAttribute('color', iconsToUse.maskIconColor)   
    }
}

const changeTitle = (): void => {
    document.title = document.hidden ? `(1) Still want to travel? ${ defaultTitle }` : defaultTitle
    setFavicons(!document.hidden)
}

onMounted(() => {
    document.addEventListener('visibilitychange', changeTitle)
})

onUnmounted(() => {
    document.removeEventListener('visibilitychange', changeTitle)
})

// console.log(page.props)

</script>

<template>
    <Head>
        <title>{{ title }}</title>

        <meta property="og:site_name" content="Hostelz">
        <meta property="og:type" content="website">
        <meta name="twitter:card" content="summary">
        <meta name="twitter:site" content="@hostelz">
        <meta name="twitter:creator" content="@hostelz">
        <meta name="p:domain_verify" content="8f16cd1b02fa89aaab4ade1941964b56">
        <meta content="text/html; charset=utf-8" http-equiv="Content-Type">
        <meta name="apple-mobile-web-app-title" content="Hostelz">
        <link rel="manifest" href="/site.webmanifest">

        <meta name="description" :content="page.props.metaData.description">
        <meta property="og:title" :content="page.props.metaData.titleGraph ?? page.props.metaData.title">
        <meta
            property="og:description"
            :content="page.props.metaData.descriptionGraph ?? page.props.metaData.description"
        >
        <meta property="og:image" :content="ogImage">
        <meta property="og:url" :content="page.props.metaData.currentUrl">
        <meta property="og:locale" content="en_US">
        <meta property="og:locale:alternate" content="en_GB">
        <meta property="og:locale:alternate" content="en_AU">

        <meta property="twitter:title" :content="page.props.metaData.titleGraph ?? page.props.metaData.title">
        <meta
            property="twitter:description"
            :content="page.props.metaData.descriptionGraph ?? page.props.metaData.description"
        >

        <link rel="canonical" :href="page.props.metaData.currentUrl">

        <link
            v-if="page.props.metaData.alternate"
            :title="page.props.metaData.alternate.title"
            :href="page.props.metaData.alternate.href"
            rel="alternate"
            type="application/rss+xml"
        >

        <slot />
    </Head>

    <TheSchema v-if="page.props.schemas?.length" :schema="page.props.schemas" />
</template>

<style scoped lang="scss">
</style>
