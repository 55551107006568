<script setup lang="ts">
import { RoomType } from '@/Pages/the-home/enums/Search'
import { HeaderSearchResultEmitType } from '@/Components/the-header-search-result/enums/HeaderSearchResultEmitType'
import { computed } from 'vue'

type Props = {
    location: string
    dates?: string
    roomType?: string
    guests?: string | number
    rooms?: string | number
    isExpanded?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    dates: 'Choose date',
    roomType: RoomType.DORM,
    guests: 'Choose guests',
    rooms: 'Choose rooms',
    isExpanded: false
})

const emit = defineEmits<{
    expand: [value: HeaderSearchResultEmitType]
}>()

const roomTypesTitle = computed(() => {
    return props.roomType === RoomType.DORM
        ? 'Dorm Bed'
        : 'Private Room'
})

</script>

<template>
    <div class="cl-text font-weight-600 header-search-result">
        <button v-if="isExpanded" class="cursor-pointer btn-clear p-0 d-none d-lg-block">
            Where are you going?
        </button>

        <div v-else id="header-search-result__info" class="cursor-pointer">
            <span class="delimiter" @click="emit('expand', HeaderSearchResultEmitType.LOCATION)">{{ location }}</span>
            <span class="delimiter" @click="emit('expand', HeaderSearchResultEmitType.DATES)">{{ dates }}</span>
            <span
                class="delimiter d-none d-lg-inline"
                @click="emit('expand', HeaderSearchResultEmitType.ROOM_TYPE)"
            >{{ roomTypesTitle }}</span>
            <span class="delimiter" @click="emit('expand', HeaderSearchResultEmitType.GUESTS)">{{ guests }}</span>
            <span
                v-if="roomType === RoomType.PRIVATE"
                class="delimiter d-none d-lg-inline"
                @click="emit('expand', HeaderSearchResultEmitType.ROOMS)"
            >{{ rooms }}</span>
           
            <button
                class="ml-3 d-lg-flex btn btn-danger btn-primary d-none justify-content-center"
                @click="emit('expand', HeaderSearchResultEmitType.LOCATION)"
            >
                <span>Let's save!</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.delimiter + .delimiter::before {
    display: inline-block;
    padding: 0 0.5rem;
    color: #787D8D;
    content: "·";

    @media (min-width: 576px) {
      padding: 0 4px;
    }

    @media (min-width: 768px) {
       padding: 0 0.5rem;
    }
}

#header-search-result__info {
    @media (min-width: 576px) {
       margin-left: 8px;
    }

    @media (min-width: 768px) {
       margin-left: 0;
    }
}
</style>